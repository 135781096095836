import React from "react";
import Container from "react-bootstrap/Container";
import "./style.css";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../../assets/logo.jpg";
import { Link } from "react-router-dom";

const Navbarr = () => {
  return (
    <Navbar
      expand="lg"
      className="bg-body-tertiary position-fixed shadow-lg border-5 rounded-bottom "
    >
      <Container>
        <Navbar.Brand href="/">
          <img alt="logo" src={logo} width="160px" height="auto" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto fw-medium fs-5 align-items-center">
            <Link class="link" to="/">
              Anasayfa
            </Link>
            <Link class="link" to="/hakkimizda">
              Hakkımızda
            </Link>
            <Link class="link" to="/yonetim-kurulu">
              Kurulumuz
            </Link>
            <Link class="link" to="/vizyon-ve-misyonumuz">
              Vizyonumuz
            </Link>
            <Link class="link" to="/faaliyetler">
            Faaliyetler
            </Link>
            <Link class="link" to="/medya">
            Medya
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navbarr;
