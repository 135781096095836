import React from "react";
import AboutCard from "../../Components/AboutCard/AboutCard";
import { motion } from "framer-motion";
import { Container } from "react-bootstrap";

const About = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Container className="py-3 mt-6 w-75 mx-auto border bg-light rounded-5 shadow-lg">
     
      <AboutCard withLink={false} />
      <p className="px-3 fw-medium mx-auto lh-lg">
       İlim ve İrşad Vakfı ve İLADER 22 Ocak 2022 tarihine kadar,
        5 üyeden oluşan bir icra kurulu tarafından yönetilmiştir ve hedeflerine
        ulaşma adına birlikte yol almışlardır. Ancak, belirtilen tarihten
        itibaren, her iki kurumun yürütme görevi ve sorumluluğu, 5 kişilik icra
        kuruluna emanet edilmiştir. Bu yeni dönemde, İlader Vakfı ve İlahiyat
        Araştırmaları Derneği, daha etkin ve verimli bir şekilde topluma hizmet
        sunma yolunda kararlılıkla ilerlemektedir. İlahiyat Araştırmaları
        Derneği, tarihleri boyunca ortaya koydukları özveri ve gayretle, manevi
        değerleri yaşatma, yaygınlaştırma ve gelecek nesillere aktarma misyonunu
        başarıyla sürdürmektedirler. Bu kutsal amaç uğruna gösterdikleri azim ve
        fedakarlık, toplumun manevi zenginliğine katkı sağlamaktadır.
      </p>
      </Container>
    </motion.div>
  );
};

export default About;
