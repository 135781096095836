import lib from "../../../assets/lib.jpg";
import lib2 from "../../../assets/lib2.jpg";
import room from "../../../assets/room.jpg";
import room2 from "../../../assets/room2.jpg";
import window from "../../../assets/window.jpg";
import ilader from "../../../assets/ilader.jpeg";
import ilader2 from "../../../assets/ilader2.jpeg";
import slider2 from "../../../assets/slider/slider-2.jpg";
import image1 from "../../../assets/card-images/image1.jpg";
import image2 from "../../../assets/card-images/image2.jpg";
import image3 from "../../../assets/card-images/image3.jpg";
import image4 from "../../../assets/card-images/image4.jpg";
import image5 from "../../../assets/card-images/image5.jpg";
import image6 from "../../../assets/card-images/image6.jpg";
import image7 from "../../../assets/card-images/image7.jpg";
import image8 from "../../../assets/card-images/image8.jpg";
const Images = [
  {
    image: lib,
  },
  {
    image: ilader2,
  },
  {
    image: room,
  },
  {
    image: window,
  },
  {
    image: lib2,
  },
  {
    image: room2,
  },
  {
    image: ilader,
  },
  {
    image: slider2,
  },
  {
    image: image1,
  },
  {
    image: image2,
  },
  {
    image: image3,
  },
  {
    image: image4,
  },
  {
    image: image5,
  },
  {
    image: image6,
  },
  {
    image: image7,
  },
  {
    image: image8,
  },
];

export default Images;
