import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import QuestionsData from "./QuestionsData";

const FrequentlyAskedQuestions = () => {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const handleAccordionToggle = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  return (
    <div>
      <Accordion flush>
        {QuestionsData.map((item, idx) => (
          <Accordion.Item
            key={idx}
            eventKey={idx.toString()}
            className="lh-lg p-2"
            active={activeAccordion === idx}
          >
            <Accordion.Header
              onClick={() => handleAccordionToggle(idx)}
              style={{ cursor: "pointer" }}
            >
              {item.question}
            </Accordion.Header>
            <Accordion.Body>
              {item.reply}
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
};

export default FrequentlyAskedQuestions;
