import React from "react";
import Card from "react-bootstrap/Card";

const AboutCard = ({ withLink }) => {
  return (
    <>
      <Card className=" border-0 pt-3 bg-light">
        <Card.Header className="border-0 bg-light">
          <h2 className="bg-blue py-3 text-white text-center">Hakkımızda</h2>
        </Card.Header>
        <Card.Body className="mx-auto">
          <Card.Text className="mx-auto fw-medium lh-lg">
            İlahiyat Araştırmaları Derneği (İLADER), ülkemizin yaşadığı 28 Şubat
            sürecinin ardından manevi ve ilmi değerlerin korunması,
            yaygınlaştırılması adına büyük bir çabanın ürünü olarak 2007 yılında
            kurulmuştur. Dernek, eğitim faaliyetlerini resmi bir çerçevede
            yürütebilmek amacıyla hayata geçirilmiştir. Özellikle iaşe ve ibate
            hizmetleri Vakıf bünyesinde, eğitim hizmetleri ise Dernek üzerinden
            yürütülmüştür. Bu iki kıymetli kurum, tarih boyunca birçok
            insanımızın manevi gelişimine katkıda bulunmuş, ilim ve irşad
            alanında önemli çalışmalara imza atmıştır. İlim ve İrşad Vakfı , toplumsal ve manevi değerlere sahip çıkma
        misyonuyla 1993 yılında temelleri atılmıştır. Bu anlamlı girişim, saygın
        şahsiyetlerin öncülüğünde gerçekleştirilmiştir. İlim ve İrşad Vakfı'nın
        temelleri, dönemin önde gelen düşünürlerinden merhum Nureddin CAN
        Hocamızın vizyoner yaklaşımı ve saygıdeğer iş insanı Sayın Murat ÜLKER
        Bey'in destekleriyle 1993 yılında atılmıştır. Vakıf, manevi değerleri
        topluma aktarma, ilim ve irşad alanında faaliyetler yürütme amacıyla
        kurulmuştur.
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
};

export default AboutCard;
